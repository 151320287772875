import React from "react"
import { Link as GaLink } from "gatsby"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import MuiLink from "@material-ui/core/Link"

import Layout from "../components/layout"
import Head from "../components/head"
import pageNotFoundSvg from "../assets/page-not-found.svg"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(15, 0, 12),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(7, 0, 4),
    },
  },
  heroGridContainer: {
    display: "grid",
    gridGap: theme.spacing(6),
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  heroImage: {
    width: "100%",
  },
  heroGridItemImage: {
    textAlign: "center",
  },
  bottomMargin: {
    marginBottom: theme.spacing(6),
  },
}))

const NotFoundPage = () => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Layout>
      <Head title="Contact" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="lg">
            <div className={classes.heroGridContainer}>
              <div>
                <div className={classes.bottomMargin}>
                  <Typography
                    component="h1"
                    variant="h2"
                    align={matches ? "left" : "center"}
                    color="textPrimary"
                    gutterBottom
                  >
                    Oops!
                  </Typography>
                  <Typography
                    variant="h5"
                    align={matches ? "left" : "center"}
                    color="textSecondary"
                    paragraph
                  >
                    We can't seem to find the page you're looking for.
                  </Typography>
                  <MuiLink
                    component={React.forwardRef((props, ref) => (
                      <GaLink innerRef={ref} {...props} />
                    ))}
                    variant="contained"
                    color="primary"
                    to="/"
                  >
                    Back to top page
                  </MuiLink>
                </div>
              </div>

              <div className={classes.heroGridItemImage}>
                <img
                  src={pageNotFoundSvg}
                  alt="Page not found"
                  className={classes.heroImage}
                />
              </div>
            </div>
          </Container>
        </div>
      </main>
    </Layout>
  )
}

export default NotFoundPage
